import type { RegularAtomToken } from "atom.io";
import { atom, atomFamily, getState, selectorFamily, setState } from "atom.io";
import { findState } from "atom.io/ephemeral";
import { fromEntries } from "atom.io/json";
import { useO } from "atom.io/react";
import type { ChatMessage } from "db";
import type { ReactRenderer } from "marked-react";
import Markdown from "marked-react";
import type { ReactNode, RefObject } from "react";
import { useEffect, useRef, useState } from "react";

import FeedbackButtons from "@/app/components/data-display/FeedbackButtons";
import ChatBox from "@/app/components/layout/ChatBox";
import useTheme from "@/app/utils/context/theme/useTheme";
import { ChatMessageRoleEnum } from "@/app/utils/enums/ChatMessageRoleEnum";
import { cn } from "@/app/utils/misc";
import { useUser } from "@/app/utils/user";

import TypingIndicator from "../animations/Typing";
import conversationStyles from "./Conversation.module.css";
import markdownStyles from "./MarkdownStyles.module.css";

const mdRenderOptions: Partial<ReactRenderer> = {
  html: (html: string) => {
    if (html === `<br>`) {
      return <br />;
    }
    return html;
  }
};

export const messageAtoms = atomFamily<
  Pick<ChatMessage, `content` | `createdAt` | `id` | `role`> & {
    isStreaming?: boolean;
  },
  string>(
  {
    key: `message`,
    default: {
      id: ``,
      role: ChatMessageRoleEnum.assistant,
      content: ``,
      createdAt: new Date()
    }
  });

export const messageIndexAtoms = atomFamily<string[], string>({
  key: `messageIndex`,
  default: []
});

export const messageIsFirstInClusterSelectors = selectorFamily<
  boolean,
  [[`conversation`, string], [`message`, string]]>(
  {
    key: `messageIsFirstInCluster`,
    get:
    (keyEntries) =>
    ({ get }) => {
      const keys = fromEntries(keyEntries);
      const knownMessageIds = get(messageIndexAtoms, keys.conversation);
      const messageIdIdx = knownMessageIds.indexOf(keys.message);
      if (messageIdIdx === -1) return false;
      if (messageIdIdx === 0) return true;
      const messagePrevId = knownMessageIds[messageIdIdx - 1];
      const prevMessage = get(messageAtoms, messagePrevId);
      const message = get(messageAtoms, keys.message);
      return prevMessage.role !== message.role;
    }
  });

export type Message = {
  id: string;
  isAI: boolean;
  text: string;
  createdAt: Date;
};

export type ConversationProps = {
  chatId: string;
};

export const conversationContainerState = atom<HTMLDivElement | null>({
  key: `conversationContainerRef`,
  default: null
});
function useAtomicRef<T extends HTMLElement>(
token: RegularAtomToken<T | null>)
: RefObject<T> {
  const ref = useRef<T | null>(null);
  useEffect(() => {
    setState(token, ref.current);
  }, [token]);
  return ref;
}
export function scrollConversationContainerToBottom() {
  const conversationContainer = getState(conversationContainerState);
  if (conversationContainer) {
    conversationContainer.scrollTop = conversationContainer.scrollHeight;
  }
}

export function Conversation({
  chatId
}: Readonly<ConversationProps>): ReactNode {
  const messageIds = useO(messageIndexAtoms, chatId);
  const { isStreaming } = useO(
    messageAtoms,
    messageIds[messageIds.length - 1] ?? ``
  );
  const conversationContainerRef = useAtomicRef(conversationContainerState);
  return (
    <div
      data-testid="Conversation-container"
      className={conversationStyles.conversation}>

			<section
        data-testid="Messages-container"
        className={conversationStyles.messages}
        ref={conversationContainerRef}>

				<ol>
					{messageIds.map((messageId, index) =>
          <MessageCard
            key={messageId}
            isLast={index === messageIds.length - 1}
            messageState={findState(messageAtoms, messageId)}
            conversationId={chatId} />

          )}
				</ol>
			</section>
			<section className={conversationStyles[`chat-box`]}>
				<ChatBox chatId={chatId} submitDisabled={isStreaming} />
			</section>
		</div>);

}

export type MessageCardProps = {
  isLast: boolean;
  messageState: RegularAtomToken<
    Pick<ChatMessage, `content` | `createdAt` | `id` | `role`> & {
      isStreaming?: boolean;
    }>;

  conversationId: string;
};

export function MessageCard({
  isLast,
  messageState,
  conversationId
}: Readonly<MessageCardProps>): ReactNode {
  const message = useO(messageState);
  const user = useUser();
  const isFirstInCluster = useO(messageIsFirstInClusterSelectors, [
  [`conversation`, conversationId],
  [`message`, message.id]]
  );
  const renderImage = () => {
    if (!isFirstInCluster) return ``;
    return (
      <img
        src={
        message.role === `user` ?
        user.profilePhoto ?? `` :
        `/img/logos/convo-logo.png`
        }
        className={conversationStyles[`user-photo`]}
        aria-hidden
        alt="Profile Photo" />);


  };

  useEffect(() => {
    if (isLast && message) {
      scrollConversationContainerToBottom();
    }
  }, [isLast, message]);

  const { isDarkMode } = useTheme();

  return (
    <li
      className={cn(
        conversationStyles.message,
        !isFirstInCluster ? conversationStyles.subsequent : ``,
        message.role === ChatMessageRoleEnum.assistant ?
        `` :
        conversationStyles.user
      )}>

			<div
        className={conversationStyles.inner}
        data-theme={isDarkMode ? `dark` : `light`}
        style={{ colorScheme: isDarkMode ? `dark` : `light` }}>

				{renderImage()}
				<div
          className={cn([
          conversationStyles.bubble,
          markdownStyles[`markdown-body`]]
          )}
          data-theme={isDarkMode ? `dark` : `light`}>

					{message.content ?
          <Markdown breaks gfm openLinksInNewTab renderer={mdRenderOptions}>
							{message.content}
						</Markdown> :

          <TypingIndicator />
          }
				</div>
				{message.role === ChatMessageRoleEnum.assistant ?
        <FeedbackButtons
          data-testid="FeedbackButtons"
          messageId={message.id} /> :

        null}
			</div>
		</li>);

}